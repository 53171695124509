<template>
    <main></main>
</template>

<script>
export default {
  name: "LegalNotes",
};
</script>

<style scoped>
    main {
        text-align: start;
        padding: 8%;
    }
</style>